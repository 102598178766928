import { atom } from "jotai"
import type { ISbStoryData } from "storyblok-js-client"

import type { GID } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"

export type Languages = Nullish<{ shopifyProductId: GID | null } | ISbStoryData>

export const languagesAtom = atom<Languages>(null)
