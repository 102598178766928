"use client"

import { Provider as JotaiProvider } from "jotai"

import { globalStore } from "~/providers/ClientAppProvider/utils/jotai-store"
import { languagesAtom, type Languages } from "~/managers/LanguagesManager"

type LanguagesProviderProps = {
  children: React.ReactNode
  languages: Languages
}

export function LanguagesProvider({ children, languages }: LanguagesProviderProps) {
  globalStore.set(languagesAtom, languages)

  return <JotaiProvider store={globalStore}>{children}</JotaiProvider>
}
