"use client"

import { useEffect } from "react"
import { usePathname } from "next/navigation"

import { PageViewAnalytics } from "@unlikelystudio/react-ecommerce-hooks"

import type { PageName } from "~/providers/GTMTrackingProvider/constants"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"

type ClientPageViewProps = {
  pageName: PageName
}

function mapPageNameToAnalyticsPageName(pageName: PageName): Parameters<typeof PageViewAnalytics>[0]["template"] {
  switch (pageName) {
    case "Home Page":
      return "index"
    case "Collection Page":
      return "collection"
    case "Product Page":
      return "product"
    case "Customer Info Page":
      return "customers/account"
    case "Customer Adresses Page":
      return "customers/addresses"
    case "Customer Orders Page":
      return "customers/order"
    case "Universal Page":
      return "page"
    case "Enabled Account Page":
      return "customers/activate_account"
    case "Login Page":
      return "customers/login"
    case "Register Page":
      return "customers/register"
    case "Forgot Password Page":
      return "customers/reset_password"
    case "Search Page":
      return "search"
    case "Le Journal Page":
      return "blog"
    case "Legals Page":
      return "policy"
    case "Reset Password Page":
      return "customers/reset_password"
    default:
      return "page"
  }
}

export function ClientPageView({ pageName }: ClientPageViewProps) {
  const { sendEvent } = useTracking()
  const pathname = usePathname()

  useEffect(() => {
    sendEvent("page_view", {
      page_title: pageName,
      page_path: pathname,
      page_location: window.location.href,
    })
  }, [])

  return <PageViewAnalytics template={mapPageNameToAnalyticsPageName(pageName)} pathname={pathname} />
}
